input {
  &[type="radio"],
  &[type="checkbox"] {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}

label {
  color: $label-color;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;

  &.uncased {
    text-transform: none;
  }
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: $text-color;
}

option {
  font-size: 0.875em;
  line-height: 18px;
  padding: 6px 13px;
  width: 100%;
}

.help-block {
  font-style: italic;
}

/* Remaining Characters inside Add to Wishlist Modal */
.right-cartName {
  margin-left: 60%;
  font-size: 12px;
  text-align: right;
}

.form-control:focus {
  border-color: $input-border-focus;
  outline: 0;
  box-shadow: none;
}

.form-control::-moz-placeholder {
  color: $placeholder-color;
  opacity: 1;
}

.form-control:-ms-input-placeholder, .form-control::-webkit-input-placeholder {
  color: $placeholder-color;
}

select.form-control {
  text-transform: uppercase;
  font-size: 13px;
}

.form-control {
  &[disabled], &[readonly], fieldset[disabled] & {
    color: $text-color-secondary;
  }
}

.has-error {
  @include form-control-validation-custom($label-color, $input-error, $input-error-bg, $input-error-text);
}

.has-success {
  @include form-control-validation-custom($label-color, $input-success, $input-success-bg, $input-success-text);
}

legend {
  font-size: 18px;
  border-bottom: 0;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
}

// For input fields with glyphicon
.form-element-icon {
  position: relative;

  .glyphicon {
    position: absolute;
  }

  &.datepicker {
    .glyphicon {
      top: 32px;
      right: 10px;
      color: $brand-primary;
      cursor: pointer;

      @media (max-width: $screenSmMax) {
        top: 38px;
      }
    }
  }
}

// Radio button styling

.form-control-radio, .form-control-checkbox {
  display: block;

  input {
    margin-bottom: 0;
    float: left;
  }

  .form-control-label {
    margin-left: 25px;
  }
}

fieldset {
  > div:last-of-type {
    input, label {
      margin-bottom: 0;
    }
  }
}

.legend {
  margin-bottom: $grid-gutter-width;
}

textarea {
  min-height: 245px;

  + .help-block {
    font-size: 12px;
    margin-bottom: 25px;
    text-align: right;
  }
}

.input-sm {
  .input-size {
    height: $input-height-small;
    padding: $input-padding-vertical-small $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    border-radius: $input-border-radius;
  }
}

@mixin input-lg {
  height: 42px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 0;
}

.input-lg {
  @include input-lg;
}

/* Toggle Button */
.toggle-button {
  position: absolute;
  top: 19px;
  right: 73px;

  // The input
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    // Disabled
    &[disabled] ~ label {
      color: lighten($border-color-3, 20%);
      pointer-events: none;

      .toggle-button__switch {
        opacity: 0.4;
      }
    }

    // Unchecked
    &:focus ~ label .toggle-button__switch, &:hover ~ label .toggle-button__switch {
      background-color: lighten($border-color-3, 20%);
      border-color: darken($border-color-3, 20%);
    }

    // Checked
    &:checked ~ label .toggle-button__switch {
      background-color: $brand-primary;

      &:after {
        content: '\00a0';
        background-color: $body-bg;
        transform: translate3d(22px, 0, 0);
      }

      :before {
        content: '\00a0';
        left: 0;
      }
    }
  }

  // The label
  label {
    display: flex;
    user-select: none;
    position: relative;
    align-items: center;

    .toggle-button__switch {
      position: relative;
      height: 33px;
      flex: 0 0 56px;
      border-radius: 60px;
      border: 1px solid $border-color-3;
      background-color: $body-bg;

      &.is-checked {
        border-color: $brand-primary;
      }

      &:before {
        left: 28px;
        font-size: 12px;
        line-height: 23px;
        width: 28px;
        padding: 0 12px;
        content: '\00a0';
        position: absolute;
        top: 0;
      }

      &:after {
        top: 4px;
        left: 4px;
        border-radius: 30px;
        width: 23px;
        line-height: 23px;
        font-size: 12px;
        color: $body-bg;
        background-color: $border-color-3;
        content: '\00a0';
        position: absolute;
        z-index: 5;
      }
    }
  }
}

// Overrides to avoid unchecked state taking precedence
.toggle-button input[type="checkbox"]:checked:focus ~ label .toggle-button__switch,
.toggle-button input[type="checkbox"]:checked:hover ~ label .toggle-button__switch {
  background-color: lighten($brand-primary, 20%);
  border-color: lighten($brand-primary, 20%);
}

/* Media Queries */

@media (max-width: $screenSmMax) {
  textarea.form-control, select[multiple].form-control {
    height: auto
  }

  .form-control {
    @include input-lg;
    font-size: $font-size-base;
    border-radius: 0;
  }
}
