/* Here are all the CSS elements related to the "Product Info" box, which can be opened near each product
 in the PLP, PDP and Cart pages. It also refers to additional product information, such as availability,
 which is displayed near the product. */

/* Product Info Box */
.mc-cart-item-box, .mc-product-item-box {
  .mc-price-promo-selector-box {
    width: 100%;
    margin-bottom: 15px;

    form {
      input, select {
        border: 1px solid #e0e0e0;
      }

      input.mc-price-promo-value {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 6px 10px;
        border-right: 0;
        max-width: 60px;
        font-weight: 700;
        font-size: 14px;
        text-align: center;

        &:focus {
          outline-offset: unset;
          outline: none;
        }
      }

      select.mc-price-promo-sel {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        min-width: 110px;
        padding-left: 20px;
      }
    }

    .mc-btn-apply-disc {
      max-width: 200px;
      background-color: #2e353d;
      border-radius: 5px !important;
      color: #ffffffff;
      font-size: 12px;
      border-color: #2e353d;
    }
  }

  .mc-cart-item-box-tabs {
    ul.nav-tabs {
      li {
        text-align: center;
        background-color: #e5e5e5;

        a {
          color: #aeb0b0;
          margin-right: 0;
          white-space: nowrap;
        }
      }

      li.active {
        background-color: $well-quaternary-bg;

        a {
          background-color: transparent;
          color: $brand-primary;
          font-weight: 600;
        }
      }
    }

    .tab-content {
      background-color: $well-quaternary-bg;
      padding: 20px 15px;
      position: relative;
      overflow-x: scroll;
      overflow: hidden;

      ul {
        li {
          list-style-type: none;
        }
      }

      table {
        margin-bottom: 0;

        tr {
          th {
            background-color: #f4f4f4;
            font-size: 11px;
          }
        }

        tr.active {
          background-color: #c1c1c1;

          td {
            background-color: #c1c1c1;
          }
        }
      }

      .mc-info-tab {
        ul {
          > h5 {
            margin-bottom: 8px;
            margin-top: 0;
            font-style: italic;
            text-decoration: underline;
          }
        }
      }

      .mcround {
        position: relative;
        height: 28px;
        width: 28px;
      }

      .mcround {
        label {
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          cursor: pointer;
          height: 28px;
          left: 0;
          position: absolute;
          top: 0;
          width: 28px;
        }
      }

      .mcround {
        label:after {
          border: 2px solid #fff;
          border-top: none;
          border-right: none;
          content: "";
          height: 6px;
          left: 7px;
          opacity: 0;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          width: 12px;
        }
      }

      .mcround input[type="checkbox"] {
        visibility: hidden;
      }

      .mcround input[type="checkbox"]:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
      }

      .mcround input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      .disabled {
        label {
          background-color: #ccc !important;
          cursor: not-allowed;
        }
      }

      .mc-ajax-loader-small {
        background-color: #000000;
        filter: alpha(opacity=60);
        -moz-opacity: 0.6;
        opacity: 0.8;
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        display: none;;
        justify-content: center;
        top: 0;
        left: 0;

        span.mc-small-ajax-circle {
          background: url("~images/mc-loading-small.svg") no-repeat center;
          background-size: 80px;
          width: 80px;
        }
      }

      .mc-promo-allegato {
        padding: 20px;

        .mc-icon-pdf {
          background: url("~images/pdf.svg") no-repeat center;
          background-size: 14px;
          background-position: calc(100% - 5px);
          float: left;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.mc-product-item-box {
  width: 100%;

  .mc-cart-item-box-tabs {
    ul.nav-tabs {
      li {
        width: 14.285%;

        a {
          padding-left: 3px;
        }
      }
    }
  }
}

.item__info {
  .item__code {
    > a {
      color: $text-color !important;
    }
  }
}

/* Product Brand */
.mc-list-brand {
  color: $brand-primary;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
}

/* Product Code Section */
.mc-list-code {
  white-space: nowrap;
  font-size: 13px;

  span > a {
    color: #828282;
  }

  a > span {
    color: #828282;
  }
}

/* Not codified items */
.mc-not-codified, .mc-not-codified a, .mc-not-codified a span {
  font-weight: 700;
  color: #909090;
}

/* Product Code Label */
.mc-code-label {
  color: #828282;
  font-size: 11px;
  font-weight: 600;
}

/* Product Code Value */
.mc-code-value {
  width: 100px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  line-height: 11px;
}

/* Price */
.price {
  del {
    color: #000;
    font-weight: bold;
  }

  ins {
    @extend .text-danger;
    text-decoration: none;
  }

  &.msrp {
    color: #666;
  }
}

/* Price in PDP */
.mc-product-detail-price {
  .mc-list-marchiolprice {
    min-height: 17px;
  }

  .mc-list-netprice {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1;
  }

  .mc-price-detail {
    float: left;
    line-height: 2.4;
    margin-left: 10px;
    text-transform: lowercase;
  }
}

/* Not available */
.mc-noavailability {
  font-size: 10px;
  color: #828282;
}

/* Availability Section */
.mc-item__availability {
  font-weight: 600;
  padding: 0 !important;
}

/* Availability Numbers */
.mc-availability {
  font-size: 11px;
  line-height: 1;
  margin-top: 1px;
  cursor: pointer;
}

/* List Price */
.mc-list-price-value {
  white-space: nowrap;
}

/* Minimum Unit Text inside PDP */
.minimum-unit {
  text-transform: lowercase;
}

/* Technical Notes link inside "Info" tab inside Cart Item */
.mcSpecUrl {
  margin-top: 10px;

  a {
    font-weight: 600;
    color: red;
  }
}

/* "Group" section inside the "Availability" tab */
.mc-group-filiale {
  color: $brand-primary;
  margin: 10px 0;
}

/* Title section inside the "Retail Availability" tab */
.mc-group-banco {
  color: $brand-primary;
  margin: 10px 0;
}

/* "No items" text inside "Product Info" box tabs */
.mc-no-Items-row {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  color: #7f919e
}

/* Replace Product section inside the "Info" box tab */
.infoSostituz {
  color: red;
  font-weight: bold;
}

/* Availability Colors */
.mc-av-red {
  color: #f44336;
}

.mc-av-orange {
  color: #ffa726;
}

.mc-av-green {
  color: #4caf50;
}

/* Product Discount */

.mc-client-discount-search {
  float: right;
  margin-top: -94px;
  color: red;
  font-size: inherit;
  font-weight: bold;
}

.mc-client-discount-detail {
  margin-top: 22px;
  position: inherit;
  color: red;
  font-size: inherit;
  font-weight: bold;
  margin-left: -12px;
}

.mc-client-discount-cart {
  color: red;
  font-size: inherit;
  font-weight: bold;
  margin-top: 5px;
  margin-left: 9px;
}

.mc-client-change-price-cart {
  font-size: inherit;
  margin-top: 5px;
  margin-left: 9px;
}

.mc-client-discount-carousel {
  margin-top: 5px;
  position: inherit;
  color: red;
  font-size: inherit;
  font-weight: bold;
}

.mc-client-discount-tooltip {
  background-color: white;
  color: #059ae3;
  font-size: 11px;
  vertical-align: 7px;
}

/* Media Queries */

@media (max-width: $screenXs) {
  .mc-cart-item-box-tabs {
    .tab-content {
      table {
        font-size: 8px;

        tr {
          th {
            font-size: 8px;
          }
        }
      }

      .mc-filiali-tab {
        padding: 5px;
        font-size: 11px !important;
      }

      font-size: 8px;

      [id*="promo-"] {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead {
          tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
          }
        }

        tr {
          border: 1px solid #ccc;
        }

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
        }

        td:before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }

        /* Label the data */
        td:nth-of-type(1):before {
          content: "NR PROMOZIONE";
        }

        td:nth-of-type(2):before {
          content: "TIPO";
        }

        td:nth-of-type(3):before {
          content: "DESC.";
        }

        td:nth-of-type(4):before {
          content: "FINE VALIDITÀ";
        }

        td:nth-of-type(5):before {
          content: "IMPORTO";
        }

        td:nth-of-type(6):before {
          content: "MP";
        }

        td:nth-of-type(7):before {
          content: "QTA RESIDUA";
        }

        td:nth-of-type(8):before {
          content: "ALLEGATO";
        }

        td:nth-of-type(9):before {
          content: "";
        }
      }

      [id*="quote-"] {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          border: 1px solid #ccc;
        }

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
        }

        td:before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }

        td:nth-of-type(1):before {
          content: "NUMERO ORDINE";
        }

        td:nth-of-type(2):before {
          content: "N. OFFERTA";
        }

        td:nth-of-type(3):before {
          content: "AGENTE";
        }

        td:nth-of-type(4):before {
          content: "DATA VAL.";
        }

        td:nth-of-type(5):before {
          content: "IMPORTO";
        }
      }

      [id*="order-"] {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          border: 1px solid #ccc;
        }

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
        }

        td:before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }

        td:nth-of-type(1):before {
          content: "NUMERO ORDINE";
        }

        td:nth-of-type(2):before {
          content: "DATA";
        }

        td:nth-of-type(3):before {
          content: "QUANTITA";
        }

        td:nth-of-type(4):before {
          content: "IMPORTO";
        }

        td:nth-of-type(5):before {
          content: "T/P";
        }
      }

      [id*="lotti-"] {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          border: 1px solid #ccc;
        }

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
        }

        td:before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }

        td:nth-of-type(1):before {
          content: "DEPOSITO";
        }

        td:nth-of-type(2):before {
          content: "COD.LOTTO";
        }

        td:nth-of-type(3):before {
          content: "TIPO LOTTO";
        }

        td:nth-of-type(4):before {
          content: "DIAMETRO";
        }

        td:nth-of-type(5):before {
          content: "RESIDUO";
        }
      }
    }

    ul {
      li {
        width: 100% !important;

        a {
          font-size: 12px !important;
        }
      }
    }

    ul {
      li.active {
        > a:after {
          @include icon-arrow-up;
          font-family: 'icomoon' !important;
          display: flex;
          float: right;
        }
      }

      li.mc-option-mobile {
        z-index: 1;
        display: none;
        list-style: none;
        background-color: #dedede !important;
      }

      li.mc-option-mobile-all-visible {
        z-index: 1;
      }

      li.active {
        display: block;
      }
    }

    .tab-content {
      padding: 0;

      .mc-order-note-box {
        .note-row {
          display: flex;
        }

        .note-col-1, .note-col-3 {
          padding: 0 !important;
        }

        .note-col-2 {
          padding: 10px;
          width: 40px;
        }

        .note-col-1 {
          position: relative;
          min-height: 40px;
        }

        .note-col-3 {
          min-height: auto;

          textarea {
            min-height: 100%;
            border: 0;
          }
        }

        .vertical-center {
          margin: 0;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        div {
          font-size: 12px;
        }

        .note-row-textarea {
          min-height: 35px;
        }
      }
    }
  }
}

@media (max-width: $screenMd) {
  .mc-client-discount-search {
    width: 146px;
    margin-top: -91px;
  }
}

@media (max-width: $screenMd) and (orientation: portrait) {
  .mc-client-discount-cart {
    margin-left: 0;
  }
}

@media (min-width: $screenMd) and (max-width: $screenMdMax) {
  .mc-availability {
    font-size: 12px !important;
  }

  .mc-cart-item-box-tabs {
    ul.nav-tabs {
      li {
        a {
          font-size: 8px;
        }
      }
    }
  }
}

@media (min-width: $screenMd) and (max-width: $screenMdBigMax) {
  .mc-product-item-box {
    .mc-cart-item-box-tabs {
      .tab-content {
        table {
          tr {
            th, td {
              font-size: 8px !important;
            }
          }
        }
      }
    }
  }

  .mc-cart-item-box-tabs {
    ul.nav-tabs {
      li {
        a {
          font-size: 9px;
        }
      }
    }
  }
}

@media (min-width: $screenMdBig) and (max-width: $screenMdBigMax) {
  .mc-availability {
    font-size: 12px !important;
  }

  .mc-cart-item-box-tabs {
    ul.nav-tabs {
      li {
        a {
          font-size: 9px;
        }
      }
    }
  }
}

@media (min-width: $screenMd) {
  .mc-availability {
    font-size: 12px !important;
  }
}
