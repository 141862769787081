/* Here are all the CSS elements related to the "Add to Cart" component. This includes the "Add to Cart" button,
 the "Pickup in Store" button, the "Add to Wishlist" button, and the Quantity Selector. */

/* Add to Cart Component */
.addtocart-component {
  margin-top: $grid-gutter-width / 2;

  .stock-wrapper {
    font-size: 13px;
    margin: 25px 0;

    * {
      float: left;
      margin: 0;
    }

    .futureStockLink {
      margin-left: $grid-gutter-width / 2;
    }
  }

  .actions {
    .btn {
      margin-bottom: $grid-gutter-width / 2;
    }
  }

  div.a2a_kit {
    a.a2a_dd.share {
      background: url("~images/productShareIcon.png") no-repeat left center;
      padding-left: 25px;

      .a2a_img {
        display: none;
      }
    }
  }
}

/* Add to Cart Form */
.mc-add_to_cart_form {
  > .mc-cart-btn {
    float: left;
    line-height: 2;
    width: 55%;
    max-width: 250px;
    margin-right: 2%;
  }
}

/* Order Now / Discover Accessories Button */
.mc-order-now {
  float: left;
  line-height: 2;
  width: 43%;
  max-width: 200px;
}

/* Quantity Selector inside Add to Cart Component */
.addtocart-component, #add_to_cart_storepickup_form {
  .qty-selector {
    .input-group-btn, .btn {
      width: floor(($padding-large-vertical * 2) + ($btn-font-size * $btn-line-height) + ($btn-border-width * 2));
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .form-control {
      width: $input-height-base * 1.5625;
      height: floor(($padding-large-vertical * 2) + ($btn-font-size * $btn-line-height) + ($btn-border-width * 2));
      text-align: center;
      font-size: 13px;
      font-weight: bold;
    }
  }
}

/* Quantity Selector Buttons */
#plusBtn, #minusBtn {
  margin-bottom: 100%;
}

/* Add to Cart Action */
.js-acc {
  padding-left: 100px;
}

/* Add to Cart Popup */
.add-to-cart {
  .add-to-cart-item {
    margin-bottom: ($grid-gutter-width/2);

    .thumb {
      float: left;
      margin-right: ($grid-gutter-width/2);

      img {
        max-width: 65px;
      }
    }

    .details {
      font-size: 18px;
      margin-left: 65px + ($grid-gutter-width / 2);

      .name {
        font-weight: bold;
        color: inherit;
      }
    }

    .price {
      font-size: 22px;
      text-align: right;
      font-weight: bold;
    }
  }
}

/* Discover Accessories */
.js-mc-PLP-discover-accessories, .js-mc-discover-accessories {
  border: 3px solid;
  color: #ffffff;
  background-color: #f16b46;
  border-color: #f16b46;

  &:hover {
    border: 3px solid;
    color: #ffffff;
    background-color: darkorange;
    border-color: #f16b46;
  }
}

/* Add to Cart Action inside Accessories Component */
.mcAccessoriesComponent-AddToCartAction {
  width: 300px;
}

/* Add to Cart Component and Quantity Selector Component inside Cart Items */
.addtocart-component, .mc-update-qty-box {
  .qty-selector {
    .btn-default {
      border-radius: 3px;
      background-color: #ffffff;
      border-color: #ffffff;
      color: #d2d2d2;
      padding: 15px;
      font-size: 12px;
      width: auto;
    }

    .form-control {
      width: 75px;
      height: 38px;
      border-color: #dfdfdf;
      border-top: 0;
      border-bottom: 0;
      border-bottom: 1px solid #eee;
    }

    .mc-input-unit-value {
      position: absolute;
      bottom: 2px;
      z-index: 2;
      font-weight: bold;
      font-size: 10px;
      left: 49%;
      margin-left: -3px;
      text-transform: lowercase;;
    }

    .input-group-btn {
      width: auto;

      button.mc-icon-plus {
        background: url("~images/plus.svg") no-repeat right center;
        box-shadow: none;
        -webkit-box-shadow: none;
        background-size: 10px;
        background-position: calc(50%);
        background-color: #fff;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      button.mc-icon-minus {
        background: url("~images/minus.svg") no-repeat right center;
        box-shadow: none;
        -webkit-box-shadow: none;
        background-size: 10px;
        background-position: calc(50%);
        background-color: #fff;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  .minimum-text {
    font-size: 10px;
    word-wrap: break-word;
    padding: 5px;
  }
}

/* Add to Cart Buttons Section */
.mc-addtocart-buttons {
  margin-top: 15px;
}

/* Add to Cart Buttons Section and Availability Section in PLP/PDP */
.mc-addtocart-buttons, .mc-addtocart-bottom-icons {
  .mc-disp-items strong {
    float: left;
  }

  .mc-lotti-icon-box {
    margin-top: 3px;
    font-size: 10px;
  }

  .mc-available-icon-box {
    margin-right: 25px;
  }

  .no-available {
    color: #a0a0a0;
  }

  [class^="icon-"], [class*=" icon-"] {
    font-size: 13px;
    margin-right: 5px;
  }
}

/* Add to Cart Component inside Quick Cart Section */
.addtocart-component.mcCartQuickOrderBox-addCartComponent, .mc-update-qty-box {
  .mcQuickQty {
    border: 2px solid #ccc;
    height: 32.5px;
  }

  .qty-selector {
    > input {
      background: transparent;
    }

    .btn-default {
      padding: 5px;
      font-size: 10px;
    }
  }

  .mc-cart-input-unit-factor {
    z-index: 1;
    bottom: -2px;
  }

  .input-group-btn {
    border: 2px solid #ccc;
  }

  .input-group-btn.left {
    border-right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

  }

  .input-group-btn.right {
    border-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    left: -0.5px;
  }
}

/* Custom MC Add to Cart Component */
.mcaddtocart-component {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;

  .actions, .qty-selector {
    margin-right: 10px;
  }

  .qty-selector {
    .input-group-btn {
      button {
        border: 1px solid;
        border-color: #cbccce;
        padding: 18px;
      }
    }

    input.form-control {
      border-top: 1px solid #cbccce;
      border-bottom: 1px solid #cbccce;
      border-left: 0;
      border-right: 0;
      margin-top: 5px;
    }
  }
}

/* Media Queries */

@media (max-width: 320px) {
  .js-qty-selector-input {
    padding: 0;
    width: 50px !important;
  }
}

@media (max-width: $screenXs) {
  .mcaddtocart-component {
    .qty-selector {
      input.form-control {
        margin-top: 0 !important;
        height: 38px !important;
      }
    }
  }

  .mc-addtocart-buttons {
    margin-top: unset;
    padding-top: 10px;
  }
}

@media (max-width: $screenXsMax) {
  .addtocart-component {
    .qty-selector {
      margin-left: auto;
      margin-right: auto;
    }

    .stock-wrapper {
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (min-device-width: $screenXs) and (max-device-width: $screenMd) and (orientation: landscape) {
  .mc-addtocart-bottom-icons {
    float: inherit;
    width: 300px;
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenSmMax) {
  .mcaddtocart-component .actions {
    min-width: 300px;
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  .mc-addtocart-buttons {
    .mc-wish-button {
      display: none;
    }
  }

  .mc-addtocart-bottom-icons .mc-disp-items, .mc-addtocart-bottom-icons .mc-noavailability {
    margin-left: 0;
    margin-top: 2px;
  }

  .mc-wish-button.mc-wish-button-tablet {
    display: block;
    float: right;
    margin-right: 10px;
  }
}

@media (max-width: $screenSmMax) {
  .addtocart-component {
    margin-top: $grid-gutter-width;

    .qty-selector {
      .form-control {
        height: 36px;
      }
    }
  }
}

@media (min-width: $screenMd) and (max-width: $screenMdMax) {
  .mcaddtocart-component {
    .actions {
      min-width: 180px;
    }
  }
}

@media (min-width: $screenMdBig) and (max-width: $screenMdBigMax) {
  .mcaddtocart-component {
    .actions {
      min-width: 220px;
    }
  }
}

@media (min-width: $screenLg) and (max-width: $screenLgMax) {
  .mcaddtocart-component {
    .actions {
      min-width: 280px;
    }
  }
}

@media (min-width: $screenXl) and (max-width: $screenXlMax) {
  .mcaddtocart-component {
    .actions {
      min-width: 280px;
    }
  }
}

@media (min-width: $screenXXl) {
  .mcaddtocart-component {
    .actions {
      min-width: 280px;
    }
  }
}
