/********** Basic HTML **********/

body {
  font-size: 12px;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;

  &.offcanvas {
    overflow-y: hidden;
  }
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

/********** Formatting **********/

.bold {
  font-weight: bold;
}

.barred {
  text-decoration: line-through;
}

/********** Links **********/

a {
  color: $link-color;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }

  &:disabled {
    pointer-events: none;
  }
}

/********** Lists **********/

ul, ol {
  padding-left: 15px;
}

/********** Styles and Semantics **********/

@mixin flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.flex {
  @include flex;
}

.no-margin {
  margin: 0 !important;
}

.display-none {
  display: none;
}

.hide {
  visibility: hidden;
}

.page-title {
  margin-bottom: 30px;
}

.main__inner-wrapper {
  padding-top: 20px;
  position: relative;
}

.col-no-padding {
  padding: 0;
}

.noPaddingDesktop {
  padding: 0;
}

.col-xs-12 {
  min-height: 0;
}

.page-headline {
  color: $headline-color;
  font-size: $font-size-h3;
  line-height: 35px;
  font-weight: 700;
  padding: $grid-gutter-width 0;
  margin: 0;

  &.border {
    border-bottom: 1px solid $border-color-2;
    margin-bottom: $grid-gutter-width + 5;
  }
}

.page-sub-headline {
  font-size: $font-size-base;
  font-weight: 400;
  line-height: 1.571;
}

.text-variant-bold {
  font-weight: 600;
}

.full-width-padding {
  padding-left: 30px;
  padding-right: 30px;
}

.half-width-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.gray-bg {
  background-color: #e5e5e5;
}

.no-margin {
  margin: 0 !important;
}

.headerGrey {
  background: grey;
  border-radius: 8px;
  color: white;
  padding: 20px;
}

.headerGrey-noMargin {
  background: grey;
  color: white;
  padding: 20px 0;
  margin: 0;
  float: left;

  .input-group {
    font-size: 16px;
  }
}

.detailsLightGrey {
  background: #f2f2f2;
  color: black;
}

.detailsMediumGrey {
  background: #b3b3b3;
  color: black;
  padding: 6px;
}

.control-no-margin {
  padding: 0;
  height: 25px;
}

.control-label-details {
  padding-top: ($padding-large-vertical + 1);
  font-size: $font-size-small;
  color: black;
  margin-left: 0;
}

.control-label-details-white {
  padding-top: ($padding-large-vertical + 1);
  font-size: $font-size-small;
  color: white;
}

.mc-cont-boxed {
  padding: 0 120px;
  position: relative;
}

.no-space {
  padding: 0 !important;

  .yCmsComponent {
    padding: 0 !important;
  }
}

.thumb {
  position: relative;

  .badges {
    position: absolute;
    top: ($grid-gutter-width/2);
    left: ($grid-gutter-width/2);
    right: ($grid-gutter-width/2);
    bottom: ($grid-gutter-width/2);
    overflow: hidden;

    .badge {
      float: left;
      clear: left;
      margin-bottom: ($grid-gutter-width/2);
    }
  }
}

.labelOrder {
  color: #ffffff;
}

.mc-divider {
  border-color: #bfbfbf;
  width: 38px;
  margin: 22px auto 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  text-align: center;
}

.alert {
  @extend .full-width-padding;
}

.alert-dismissable, .alert-dismissible {
  .close {
    right: 0;
  }
}

.container__full {
  @extend .full-width-padding;
}

/********** Others **********/

.mc-ajax-loader {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  display: none;

  img {
    z-index: 3000;
    width: 110px;
    height: 110px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -70px 0 0 -70px;
  }
}

.stop-scrolling {
  overflow: hidden;
  position: relative;
  height: 100%;
}

/**********	Well **********/

.well {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: $grayblue;
  border: none;
  border-bottom: 1px solid $table-head-border-color;
  padding: $grid-gutter-width/2 15px;
  margin: 0;
  @extend .clearfix;

  &.well-single-headline {
    padding-left: 0;
    padding-right: 0;
  }

  &.well-xs {
    padding: 0 15px 5px;

    .well-headline {
      padding-top: 15px;
      margin: 0;
    }

    .well-content {
      padding-top: $grid-gutter-width;
    }
  }

  &.well-sm {
    padding: $grid-gutter-width/2 $grid-gutter-width;
  }

  &.well-md {
    padding-top: $grid-gutter-width - 5;
    padding-bottom: $grid-gutter-width - 5;
    @extend .full-width-padding;
  }

  &.well-lg {
    padding-top: 45px;
    padding-bottom: 35px;
    @extend .full-width-padding;
  }

  &.well-primary {
    background-color: $brand-primary;
    color: #ffffff;
  }

  &.well-secondary {
    background-color: $well-secondary-bg;
    color: $text-color;
  }

  &.well-tertiary {
    background-color: $table-head-bg;
    color: #ffffff;

    .item-label {
      color: $table-head-color;
    }

    .item-value {
      color: #fff;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  &.well-quaternary {
    background-color: $well-quaternary-bg;
    color: $text-color;
    border-top: 1px solid $border-color-2;
    border-bottom: 1px solid $border-color-2;
  }

  &.well-quinary {
    background-color: #ffffff;
    color: $text-color;
    border-color: $border-color-2;
    padding-left: 0;
    padding-right: 0;

    .well-headline {
      background-color: $table-head-bg;
      color: #ffffff;
    }

    .well-content {
      @extend .full-width-padding;
    }
  }

  &-headline {
    @extend .full-width-padding;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid $table-head-border-color;
    padding: 5px 30px 15px;

    &-sub {
      font-weight: 400;
      padding-left: 10px;
    }
  }

  &-single-headline {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &-content {
    @extend .clearfix;
    padding-top: $grid-gutter-width*2;
    padding-bottom: $grid-gutter-width;
  }

  > .col-no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .well-headline {
    @extend .full-width-padding;
    margin: 0;
  }
}

/* Media Queries */

@media (max-width: $screenXs) {
  .mc-ajax-loader img {
    margin: -55px 0 0 -55px !important;
  }

  .noPaddingXS {
    padding: 0;
  }

  .marginTop15 {
    margin-top: 15px;
  }

  .mc-cont-boxed {
    padding: 0;
    position: relative;
  }
}

@media (min-width: $screenSm) {
  main {
    transition: transform 0.3s ease-out;
  }
}

@media (max-width: $screenXsMax) {
  .well {
    &,
    &.well-lg {
      padding-left: 20px;
      padding-right: 20px;
    }

    &-headline {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

@media (max-width: $screenSmMax) {
  body.offcanvas {
    max-height: 100vh;
  }

  main {
    transition: transform 0.3s ease-out;
  }

  .container {
    width: $full-width;
  }
}

@media (min-width: $screenXXl) {
  body {
    font-size: 14px !important;
  }

  .container {
    width: 1780px;
  }
}

@media (min-width: $screenXl) and (max-width: $screenXlMax) {
  .container {
    width: 1480px;
  }
}

@media (min-width: $screenLg) and (max-width: $screenLgMax) {
  .container {
    width: 1360px;
  }
}

@media (min-width: 1025px) and (max-width: $screenMdBigMax) {
  .col-sm-no-pad {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: $screenMdBig) and (max-width: $screenMdBigMax) {
  .container {
    width: 1220px;
  }
}

@media (min-width: $screenMd) and (max-width: $screenMdMax) {
  .container {
    width: 1000px;
  }
}

@media (min-width: $screenMd) {
  .half-width-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .noPaddingJustD {
    padding: 0;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: $screenLg) {
  .container-lg {
    float: none;
    margin: 0 auto !important;
    overflow: hidden;
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  .mc-home-boxed, .mc-cont-boxed {
    padding: 0 20px !important;
  }

  .stop-scrolling {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
}

@media (max-width: $screenMd) {
  .alert {
    padding-left: 20px;
    padding-right: 20px;
  }

  .well {
    &.well-md {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.well-lg {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
