/* Here are all the CSS elements related to the "Slider Viewer" component (e.g., the one in the Homepage),
 which makes use of the "jQuery SlideViewer" library. */

/* Slider Viewer Component */
.slider_component {
  position: relative;
}

/* Homepage Slider Viewer */
#homepage_slider {
  ul {
    padding-left: 0;

    li {
      list-style-type: none;

      a {
        > img {
          width: 100%;
        }
      }
    }
  }

  &.stripViewer {
    position: relative;
    overflow: hidden;
    margin: 0;

    ul {
      margin: 0;
      padding: 0;
      position: relative;
      left: 0;
      top: 0;
      width: 1%;
      list-style-type: none;

      li {
        float: left;
      }
    }
  }

  .svw {
    width: 50px;
    height: 20px;
    background-color: #ffffff;

    ul {
      position: relative;
      left: -999em;
    }
  }

  .tooltip {
    padding: 0.5em;
    background-color: #ffffff;
    color: #000000;
    border: 5px solid #dedede;
  }
}

/* Slider Viewer buttons to change image */
.stripTransmitter {
  position: absolute;
  bottom: 0;
  right: 0;
  background: transparent;
  border-bottom: 0;
  width: auto;
  overflow: auto;
  padding: 0 15px;
  height: 51px;

  ul {
    margin: 0;
    padding: 0;
    position: relative;
    list-style-type: none;

    > li {
      width: auto;
      float: left;

      a {
        background: RGBA(255, 255, 255, 0.31);
        opacity: 1;
        padding: 5px 5px 5px 5px;
        box-shadow: none;
        border-width: 0;
        border-style: solid;
        border-color: RGBA(0, 0, 0, 1);
        border-radius: 50px;
        margin: 4px;

        &.current {
          background: #ffffff;
        }
      }
    }
  }

  a {
    display: block;
    padding: 0 0 0 0;
    text-align: center;
    text-decoration: none;
    font-size: 1.4em;

    &:hover, &.current {
      font-weight: bold;
    }
  }
}

/* Tooltip when hovering the mouse on the buttons to change image */
.tooltip {
  padding: 0.5em;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px dotted #A5A5A5;
}

/* Slider Viewer loader */
.slider-loader {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;

  img {
    z-index: 3000;
    width: 60px;
    height: 60px;
    top: 50%;
    position: relative;
  }
}
