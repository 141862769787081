.page-homepage {
  .main__inner-wrapper {
    padding-top: 0;
  }
}
/* .mc-home-first-section,
.mc-homepage-second-section,
.mc-half-left,
.mc-half-right {
  float: left;
} */

.mc-half-left,
.mc-half-right {
  width: 50%;
}

.mc-half-left {
  border-right: 1px solid #fff;
}

.home-right-banner-1 {
  border-bottom: 1px solid #fff;
  margin-top: -1px;
}

.mc-home-first-section {
  border-right: 1px solid #ffffff;
  padding-left: 0;
  padding-right: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.mc-homepage-second-section {
  padding-left: 0;
  padding-right: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.mc-home-boxed {
  padding: 0 120px;
  position: relative;
  min-height: 20px;
}

.marchiol-middle-banner-cont {
  .middle-banner {
    margin-top: -90px;
  }
}

.marchiolWhoWeAreContainer {
  background: url("~images/mc-cont-fluid-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  margin-bottom: 180px;
}

.mcWhoWeAre {
  padding: 40px 0;

  .title {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  .mc-divider {
    border-color: #696e71;
  }

  .evidence-cont {
    margin-top: 35px;

    .evidence {
      .bold {
        font-weight: 800;
        width: 100%;
        text-align: center;
        color: $brand-primary;
        font-size: 24px;
        text-transform: uppercase;
      }

      .text {
        color: #e1e1e1;
        font-weight: 400;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.user-console {
  background-color: #fff;
  padding: 10px 0;
  visibility: hidden;

  .btn-userconsole {
    background-color: #04a0eb;
    border-radius: 5px !important;
    color: #fff;
    margin-bottom: 10px;
    width: 80%;

    span {
      float: left;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      float: right !important;
      font-size: 20px;
    }
  }
}

/* Media Queries */

@media (max-width: $screenMd) {
  .mc-home-first-section {
    width: 100%;
  }
}

@media (max-width: $screenXs) {
  .mc-home-boxed {
    padding: 0 !important;
    min-height: 0;
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  .mc-homepage-second-section {
    display: none;
  }
}

