/* Here are all the CSS elements related to icons. */

.icon-base,
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon-arrow-down {
  content: "\e900";
}

.icon-arrow-down,
.icon-arrow-down:before {
  @include icon-arrow-down;
}

@mixin icon-arrow-down_2 {
  content: "\e901";
}

.icon-arrow-down_2,
.icon-arrow-down_2:before {
  @include icon-arrow-down_2;
}

.icon-arrow-down_3:before {
  content: "\e902";
}

.icon-arrow-left:before {
  content: "\e903";
}

.icon-arrow-left_2,
.icon-arrow-left_2:before {
  content: "\e904";
}

.icon-arrow-left-previous:before {
  content: "\e905";
}

.icon-arrow-right:before {
  content: "\e906";
}

@mixin icon-arrow-right_2 {
  content: "\e907";
}

.icon-arrow-right_2,
.icon-arrow-right_2:before {
  @include icon-arrow-right_2;
}

.icon-arrow-right-next:before {
  content: "\e908";
}

@mixin icon-arrow-up {
  content: "\e909";
}

.icon-arrow-up,
.icon-arrow-up:before {
  @include icon-arrow-up;
}

.icon-arrow-up_2:before {
  content: "\e90a";
}

.icon-assistenza:before {
  content: "\e90b";
}

.icon-automazione:before {
  content: "\e90c";
}

.icon-back-arrow:before {
  content: "\e90d";
  color: #484848;
}

.icon-barcode:before {
  content: "\e90e";
}

.icon-box_1:before {
  content: "\e90f";
}

.icon-box_2:before {
  content: "\e910";
}

.icon-box_3:before {
  content: "\e911";
}

.icon-boxes:before {
  content: "\e912";
}

.icon-cablaggio-strutturato:before {
  content: "\e913";
}

.icon-calendar:before {
  content: "\e914";
}

.icon-cancel:before {
  content: "\e915";
}

.icon-carpenteria:before {
  content: "\e916";
}

.icon-carrelli-salvati:before {
  content: "\e917";
}

.icon-cart:before {
  content: "\e918";
}

.icon-cart_2:before {
  content: "\e919";
}

.icon-cart_3:before {
  content: "\e91a";
}

.icon-cavi:before {
  content: "\e91b";
}

.icon-check-mark:before {
  content: "\e91c";
}

.icon-check-mark_2:before {
  content: "\e91d";
}

.icon-cigcup:before {
  content: "\e91e";
}

.icon-climatizzazione:before {
  content: "\e91f";
}

.icon-credit-card:before {
  content: "\e920";
}

.icon-dati-cliente:before {
  content: "\e921";
}

.icon-dati_cliente:before {
  content: "\e922";
}

.icon-dati-personali:before {
  content: "\e923";
}

.icon-delete:before {
  content: "\e924";
}

.icon-delivery-truck_1:before {
  content: "\e925";
}

.icon-delivery-truck_2:before {
  content: "\e926";
}

.icon-domotica:before {
  content: "\e927";
}

.icon-download:before {
  content: "\e928";
}

.icon-download-listini-fornitori:before {
  content: "\e929";
}

.icon-edit:before {
  content: "\e92a";
}

.icon-equalizer:before {
  content: "\e92b";
}

.icon-facebook-circular-logo:before {
  content: "\e92c";
}

.icon-FAQ:before {
  content: "\e92d";
}

.icon-fast:before {
  content: "\e92e";
}

.icon-fatturazione:before {
  content: "\e92f";
}

.icon-fatture:before {
  content: "\e930";
}

.icon-flash:before {
  content: "\e931";
}

.icon-fotovoltaico:before {
  content: "\e932";
}

.icon-gestione-ordine:before {
  content: "\e933";
}

.icon-gestione-resi:before {
  content: "\e934";
}

.icon-grid:before {
  content: "\e935";
}

.icon-hamburger-menu:before {
  content: "\e936";
}

.icon-id-card:before {
  content: "\e937";
}

.icon-id-card_2:before {
  content: "\e938";
}

.icon-id-card_3:before {
  content: "\e939";
}

.icon-import:before {
  content: "\e93a";
}

.icon-information-button:before {
  content: "\e93b";
}

.icon-invio-ordini:before {
  content: "\e93c";
}

.icon-L1_010CategoryLink:before {
  content: "\e93d";
}

.icon-L1_020CategoryLink:before {
  content: "\e93e";
}

.icon-L1_030CategoryLink:before {
  content: "\e93f";
}

.icon-L1_040CategoryLink:before {
  content: "\e940";
}

.icon-L1_050CategoryLink:before {
  content: "\e941";
}

.icon-L1_060CategoryLink:before {
  content: "\e942";
}

.icon-L1_070CategoryLink:before {
  content: "\e943";
}

.icon-L1_080CategoryLink:before {
  content: "\e944";
}

.icon-L1_090CategoryLink:before {
  content: "\e945";
}

.icon-L1_100CategoryLink:before {
  content: "\e946";
}

.icon-lamp:before {
  content: "\e947";
}

.icon-lighting:before {
  content: "\e948";
}

.icon-linkedin:before {
  content: "\e949";
}

.icon-list:before {
  content: "\e94a";
}

.icon-location-mark_1:before {
  content: "\e94b";
}

.icon-location-mark_2:before {
  content: "\e94c";
}

.icon-location-mark_3:before {
  content: "\e94d";
}

.icon-mail:before {
  content: "\e94e";
}

.icon-mail_2:before {
  content: "\e94f";
}

.icon-materiale-civile-e-industriale:before {
  content: "\e950";
}

.icon-megaphone:before {
  content: "\e951";
}

.icon-microsoft-excel:before {
  content: "\e952";
}

.icon-minus:before {
  content: "\e953";
}

.icon-minus_2:before {
  content: "\e954";
}

.icon-modalita-pagamento:before {
  content: "\e955";
}

.icon-open-magazine:before {
  content: "\e956";
}

.icon-ordini-ricorrenti:before {
  content: "\e957";
}

.icon-pdf:before {
  content: "\e958";
}

.icon-phone:before {
  content: "\e959";
}

.icon-plus:before {
  content: "\e95a";
}

.icon-plus_2:before {
  content: "\e95b";
}

.icon-plus_2_thin:before {
  content: "\e95c";
}

.icon-preventivi:before {
  content: "\e95d";
}

.icon-printer:before {
  content: "\e95e";
}

.icon-rate:before {
  content: "\e95f";
}

.icon-resize:before {
  content: "\e960";
}

.icon-riferimenti:before {
  content: "\e961";
}

.icon-rubrica-indirizzi:before {
  content: "\e962";
}

.icon-save:before {
  content: "\e963";
}

.icon-search:before {
  content: "\e964";
}

.icon-security:before {
  content: "\e965";
}

.icon-share:before {
  content: "\e966";
}

.icon-sicurezza:before {
  content: "\e967";
}

.icon-statistiche:before {
  content: "\e968";
}

.icon-storico-ddt:before {
  content: "\e969";
}

.icon-storico-fatture:before {
  content: "\e96a";
}

.icon-storico-offerte:before {
  content: "\e96b";
}

.icon-storico-offerte-thin:before {
  content: "\e96c";
}

.icon-storico-ordini:before {
  content: "\e96d";
}

.icon-tag:before {
  content: "\e96e";
}

.icon-tag_1:before {
  content: "\e96f";
}

.icon-tag_2:before {
  content: "\e970";
}

.icon-tick-inside-circle:before {
  content: "\e971";
}

.icon-tools:before {
  content: "\e972";
}

.icon-trolley:before {
  content: "\e973";
}

@mixin icon-turn-off {
  content: "\e974";
}

.icon-turn-off,
.icon-turn-off:before {
  @include icon-turn-off;
}

.icon-user:before {
  content: "\e975";
}

.icon-wishlist:before {
  content: "\e976";
}

.icon-youtube:before {
  content: "\e977";
}

.icon-buyed:before {
  content: "\e978";
}

.icon-folder-open:before {
  content: "\e979";
}

.icon-checkbox-unchecked:before {
  content: "\e980";
}

.icon-checkbox-checked:before {
  content: "\e981";
}

.mini-cart-icon {
  color: $nav-icon-color;
  font-size: 22px;

  .icon-cart {
    top: 0;
  }
}

.mc-icon {
  color: $nav-icon-color;
  font-size: $nav-icon-color-font-size;
  width: 15px;
  height: 15px;
  display: block;
}

.mc-icon-large {
  height: 40px;
  width: 40px;
  float: left;
  background-size: 35px !important;
  margin-right: 8px;
}

.mc-icon-xlarge {
  height: 80px;
  width: 80px;
  float: left;
  background-size: 80px !important;
  margin: 10px;
}

.mc-check-icon {
  font-size: 18px;
  color: green;
}

.mc-delivery-truck-icon {
  @extend .mc-icon-large;
  background: url('~images/delivery-truck_1.svg') no-repeat center;
}

.mc-delivery-trolley-icon {
  @extend .mc-icon-large;
  background: url('~images/trolley.svg') no-repeat center;
}

.mc-available-icon, .mc-lotti-icon {
  font-size: 13px;
  font-weight: bold;
  float: left;
}

.mc-available-icon-box, .mc-lotti-icon-box {
  line-height: 1.4;
  float: left;
  font-weight: 600;
}

.mc-available-icon-box {
  color: $brand-primary;
  font-weight: 700;
}

.mc-lotti-icon-box, .mc-mail-icon-box {
  color: #747b81;
}

.mc-addtocart-product-bottom-icons {
  .mc-lotti-icon-box {
    margin-right: 30px;
  }
}

.mc-available-icon {
  background: url("~images/check-mark_2_green.svg") no-repeat center;
  background-size: 14px;
  background-position: calc(95%);
  margin-right: 5px;
}

.mc-youtube {
  background: url("~images/youtube.svg") no-repeat center;
  background-size: 15px;
  background-position: calc(95%);
}

.mc-linkedin {
  background: url("~images/linkedin.svg") no-repeat center;
  background-size: 25px;
  background-position: calc(95%);
  height: 25px;
  width: 25px;
}

.mc-lotti-icon {
  background: url("~images/box_1_gray.svg") no-repeat center;
  background-size: 14px;
  margin-right: 5px;
}

.mc-mail-icon {
  background: url("~images/mail_gray.svg") no-repeat center;
  background-size: 14px;
  margin-right: 8px;
}

.mc-cart2-icon {
  background: url("~images/cart_3_gray.svg") no-repeat center;
}

.mc-id-card-icon {
  background: url("~images/id-card_gray.svg") no-repeat center;
}

.mc-import-export-icon {
  background: url("~images/import_cartaction.svg") no-repeat center;
  background-size: 14px;
  background-position: calc(95%);
  margin-right: 8px;
}

.mc-quick-order-icon {
  background: url('~images/mcfast.svg') no-repeat center;
  background-size: 20px;
  float: right;
  margin-top: -1px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  display: block;
}

.mc-user-head-icon {
  background: url("~images/id-card_4_header.svg") no-repeat center;
  background-size: 14px;
  background-position: calc(95%);
  margin-right: 8px;
}

.mc-location-head-icon {
  background: url("~images/location-mark_header.svg") no-repeat center;
  background-size: 14px;
  background-position: calc(95%);
  margin-right: 8px;
}

.mc-menu-user-icon {
  background: url("~images/user_mc.svg") no-repeat center;
  background-size: 18px;
  width: 18px;
  display: block;
  height: 18px;
  margin-top: 12px;
}

.mc-menu-wish-icon {
  background: url("~images/wishlist_mc.svg") no-repeat center;
  background-size: 18px;
  width: 18px;
  display: block;
  height: 18px;
  margin-top: 7px;
}

.mc-menu-products-icon {
  &:hover {
    color: #2f353b;
  }
}

.mc-mail-icon {
  background: url("~images/mail_gray.svg") no-repeat center;
  background-size: 14px;
  margin-right: 8px;
}

.mc-print-cart-icon {
  background: url("~images/printer_cart.svg") no-repeat center;
  background-size: 14px;
  background-position: calc(95%);
  margin-right: 8px;
}

.mc-delete-cart-icon {
  background: url("~images/delete_cart.svg") no-repeat center;
  background-size: 14px;
  background-position: calc(95%);
  margin-right: 8px;
}

.mc-save-cart-icon {
  background: url("~images/wishlist_mc.svg") no-repeat center;
  background-size: 14px;
  background-position: calc(95%);
  margin-right: 8px;
}

.mc-REMOVE-cart-item-icon {
  background: url('~images/delete_cart.svg') no-repeat center;
  background-size: 18px;
  float: left;
  width: 30px;
  height: 30px;
}

.mc-edit-cart-item-icon {
  background: url('~images/edit_cart.svg') no-repeat center;
  background-size: 18px;
  float: left;
  width: 30px;
  height: 30px;
}

.mc-note-blocked-icon {
  background: url('~images/mc-lock.svg') no-repeat center;
  background-size: 18px;
  float: left;
  width: 30px;
  height: 30px;
}

.mc-note-unblocked-icon {
  background: url('~images/mc-open-lock.svg') no-repeat center;
  background-size: 18px;
  float: left;
  width: 30px;
  height: 30px;
}

.mc-list-product-icons-bar {
  button {
    border: 1px solid $mc-product-icons-color;
    cursor: pointer;
    background-color: transparent;
    color: #d3d3d3;
    margin-right: 5px;
    font-size: 14px;
  }

  button.active {
    background-color: #ed7d21 !important;
    color: #ffffff;
  }

  button.present {
    background-color: $mc-active-icon-color;
    color: #ffffff;
  }
}

button.mc-icons-tag {
  background: url("~images/tag_product.svg") no-repeat right center;
  background-color: #ffffff;
}

.mc-lots-available-icon {
  color: #878e93;
}

.icon-information-button {
  color: #fff;
}

.icon-information-button-gray {
  color: #747b81;
}

.icon-wishlist.mc-wish-button {
  color: #969a97;
}

.icon-wishlist.mc-wish-button {
  color: #969a97;
}

/* Work with us */
.mc-btn-uploadfile {
  background: #32c5d2;
  border-color: #32c5d2;
  color: #fff;
}

.c-checkbox {
  label {
    font-size: 13px;
    font-weight: 300;
    cursor: pointer;
  }
}

.wwuform-size-desc {
  margin-bottom: 15px;

  strong {
    font-weight: normal;
    color: #458C17;
  }
}

/* Button icon */
.btn-icon {
  padding-left: $btn-icon-font-size + $padding-large-horizontal + ($grid-gutter-width * 2) + $btn-border-width;
  position: relative;

  &:before {
    position: absolute;
    font-size: $btn-icon-font-size;
    top: 9px;
    left: $grid-gutter-width;
    display: inline-block;
    font-family: 'Glyphicons Halflings', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: $btn-icon-font-size + ($grid-gutter-width * 2);
    top: -$btn-border-width;
    border-right: $btn-border-width solid currentColor;
    height: floor(($padding-large-vertical * 2) + ($btn-font-size * $btn-line-height) + ($btn-border-width * 2));

    &:hover {
      border-right-width: $btn-border-width;
    }
  }
}
