/* Here are all the CSS elements related to the "Product Listing" section (e.g., PLP). */

/* Product List Wrapper */
.product__list--wrapper {
  @media (min-width: $screenMd) {
    padding: 0;
    margin: 0 0 0 -20px;
  }

  .pagination-wrap {
    @media (min-width: $screenSm) {
      padding-right: 0;
    }
  }
}

.product__list--wrapper, .mcproduct__list--wrapper {
  ul {
    li {
      img {
        width: 300px;
      }

      .mc-product-list-tools {
        .addtocart-component {
          .mc-addtocart-buttons, .mc-addtocart-bottom-icons {
            .mc-available-icon-box {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}

/* Product Listing */
.product__listing {
  @extend .list-unstyled;

  .product__listing--promo {
    @extend .text-success;
    margin-bottom: ($grid-gutter-width/2);

    @media (min-width: $screenMd) {
      padding-right: 20px;
      margin: 0 -10px;
    }
  }

  .product__list--price-panel {
    text-align: right;
  }

  .product__list--name {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 ($grid-gutter-width/2) 106px;
    color: inherit;
  }

  .product__listing--description {
    @extend .hidden-xs;
    margin: 0 210px 0 106px;
  }

  .addtocart {
    clear: both;
    text-align: center;
    padding: ($grid-gutter-width/2) 0 0 0;

    button {
      margin-bottom: ($grid-gutter-width/4);
    }

    @media (min-width: $screenMd) {
      .pickup-in-store-available {
        .ProductGridComponent-ListPickUpInStoreAction,
        .ProductGridComponent-ListAddToCartAction,
        .SearchResultsList-ListPickUpInStoreAction,
        .SearchResultsList-ListAddToCartAction,
        .SearchResultsGrid-ListPickUpInStoreAction,
        .SearchResultsGrid-ListAddToCartAction {
          width: 49%;
          display: inline-block;
          float: left;
        }

        .ProductGridComponent-ListAddToCartAction {
          position: relative;
          right: 1%;
          float: right;
        }

        .ProductGridComponent-ListPickUpInStoreAction {
          position: relative;
          left: 1%;
        }

        @media (min-width: 1200px) {
          .ProductGridComponent-ListAddToCartAction, .ProductGridComponent-ListPickUpInStoreAction {
            $percent: 49%;
            width: calc(#{$percent} - 4px);
            // width: calc(~'49% - 4px');
          }
        }
      }
    }

    @media (min-width: $screenMd) and (max-width: $screenMdBigMax) {
      .pickup-in-store-available {
        margin: 0 -($grid-gutter-width/5);

        .ProductGridComponent-ListPickUpInStoreAction,
        .ProductGridComponent-ListAddToCartAction,
        .SearchResultsList-ListPickUpInStoreAction,
        .SearchResultsList-ListAddToCartAction,
        .SearchResultsGrid-ListPickUpInStoreAction,
        .SearchResultsGrid-ListAddToCartAction {
          padding: 0 ($grid-gutter-width/6);
        }
      }
    }
  }

  &.product__list .product__list--item, &.product__grid .product-item {
    &.tag-highlighted {
      position: relative;

      &:before {
        content: "";
        z-index: -1;
        position: absolute;
        border: 2px solid $brand-primary;
        top: 14px;
        left: 12px;
        right: 12px;
        bottom: 14px;
      }

      &:after {
        @extend .glyphicon;
        content: "\e006";
        top: 18px;
        left: 16px;
        color: $brand-primary;
        width: 24px;
        line-height: 22px;
        height: 24px;
        font-size: 18px;
        padding: 0;
        text-align: center;
        position: absolute;
      }
    }
  }

  &.product__list {
    .product__list--item {
      padding: $grid-gutter-width 30px;
      border-top: 1px solid $border-color;

      &:nth-of-type(2n) {
        background-color: transparent;
      }

      @media (max-width: $screenMd) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &.tag-highlighted {
        &:before {
          left: 0;
          right: 0;
          top: 1px;
          bottom: 0;
        }

        &:after {
          font-size: 16px;
          top: 8px;
          left: 7px;
        }

        &:first-child {
          &:before {
            top: 0;
          }
        }

        &:nth-of-type(2n) {
          background: transparent;

          &:before {
            top: 1px;
            background-color: $table-2n-line-bg;
          }
        }
      }
    }

    .product__list--thumb {
      float: left;
      margin-right: ($grid-gutter-width/2);
      display: block;

      > img {
        max-width: 96px;
        max-height: 96px;
        width: auto;
      }
    }

    .product__list--price-panel {
      float: right;
      width: 200px;
    }

    .ProductListComponent-ListPickUpInStoreAction,
    .ProductListComponent-ListAddToCartAction,
    .SearchResultsList-ListPickUpInStoreAction,
    .SearchResultsList-ListAddToCartAction {
      @include make-xs-column(6);
      @include make-sm-column(4);
      @include make-sm-column-push(4);
    }

    .ProductListComponent-ListOrderFormAction, .SearchResultsList-ListOrderFormAction {
      @include make-xs-column(6);
      @include make-xs-column-push(6);
      @include make-sm-column(4);
      @include make-sm-column-push(0);
    }

    @media (max-width: $screenXsMax) {
      .product__list--price-panel {
        text-align: left;
        float: none;
        margin-left: 106px;
        width: auto;
      }
    }
  }

  &.product__grid {
    display: flex;
    flex-wrap: wrap;

    .product-item {
      @include make-xs-column(6);
      @include make-sm-column(3);
      @include make-md-column(3);
      @include make-lg-column(3);

      padding: $grid-gutter-width;
      @include flex-display(flex);
      @include flex-direction(column);

      // Fix for Safari
      &:first-child {
        margin-left: -1px;
      }

      &.tag-highlighted {
        position: relative;
      }
    }

    .thumb {
      display: block;
      margin-bottom: ($grid-gutter-width/2);
      text-align: center;
      height: 180px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        max-width: 140px;
      }
    }

    .details {
      text-align: center;
      margin: 0 auto;
      width: 100%;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1 0 auto;

      .name {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        padding-bottom: ($grid-gutter-width/2);
        height: 70px;
        overflow: hidden;
        color: $headline-color;
      }

      .price {
        font-size: 18px;
      }

      .promo {
        color: $item-promo-color;
        font-size: 14px;
        font-weight: bold;
      }
    }

    /* Clearfixes */
    @media (min-width: $screenSm) {
      .product-item:nth-child(4n+1) {
        clear: both;
      }
    }

    @media (max-width: $screenXsMax) {
      .product-item:nth-child(2n+1) {
        clear: both;
      }
    }
  }
}

.mcproduct__list {
  li.product__list--item {
    border-top: 1px solid $mc-border-soft !important;
    padding: 10px !important;
    position: relative;
    overflow: hidden;

    .mc-product__list--name {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      color: inherit;
    }

    .mc-product__listing--description {
      margin-top: 15px;
      color: #9b9b9b;

      a {
        color: #9b9b9b;
      }
    }

    .mc-list-product-icons-bar {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 15px;
    }

    .mc-product-list-new {
      background-color: $mc-btn-new-bg;
      height: 80px;
      left: -50px;
      position: absolute;
      top: -50px;
      width: 100px;
      -webkit-transform: rotate(-45deg);

      span {
        position: absolute;
        bottom: 3px;
        left: 30px;
        font-size: 11px;
        color: #fff;
        font-weight: 700;
      }
    }

    a.product__list--thumb {
      margin-top: 20px;
      float: unset !important;
    }
  }
}

/* Product List Item Image */
.mc-image {
  text-align: center;
}

/* Product List Item Info Section (e.g., name, codes, etc...) */
.mc-product-list-info {
  padding-top: 20px;
}

/* Product List Item Tools Section (e.g., price, quantity selector, product info, etc...) */
.mc-product-list-tools {
  background: #f0f0f0;
  padding: 10px;
}

/* Product List Item Price Panel */
.mc-product__list--price-panel {
  .mc-list-marchiolprice {
    min-height: 17px;
  }

  .mc-list-netprice {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1;
  }

  .mc-price-detail {
    float: left;
    line-height: 2.4;
    margin-left: 10px;
    text-transform: lowercase;
  }

  .mc-list-vat-label, .mc-list-info-label {
    font-size: 13px;
  }

  .mc-list-vat-label {
    color: #919191;
    margin-right: 5px;
  }

  .mc-list-info-label {
    color: #007ec5;
  }
}

/* Product List Item Net Price */
.product__list--item {
  .mc-product-list-tools {
    .mc-product__list--price-panel {
      .price {
        .mc-list-netprice {
          float: left;
        }
      }
    }
  }
}

/* Media Queries */

@media (max-width: $screenXs) {
  .product__listing.product__list {
    .product__list--thumb {
      > img {
        max-width: 100%;
        max-height: 200px;
        width: auto;
      }
    }
  }

  .mc-image-xs {
    padding: 30px 50px;

    a {
      width: 100%;
      margin-top: unset;

      img {
        display: block;
        margin: auto;
        min-width: 96px;
      }
    }
  }

  .mc-product-list-info {
    padding-top: 0;
    position: static;
  }

  .mc-product__listing--description {
    margin-bottom: 15px;
  }

  .mc-list-product-icons-bar {
    margin-top: 10px;
  }

  .price {
    .mc-list-netprice {
      font-size: 20px;
    }
  }

  .mc-product-list-tools {
    padding: 10px 0;
    display: inline-block;

    .addtocart-component {
      margin-top: unset;

      .mc-nopaddingleft {
        padding-left: 0;
        padding-right: 0;
      }

      .mc-marginbottom8 {
        margin-bottom: 8px;
      }

      .mc-addtocart-buttons, .mc-addtocart-bottom-icons {
        margin-top: unset;
        padding-top: 10px;

        .mc-available-icon-box {
          padding-top: 10px;
        }
      }
    }
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  .product__listing.product__list {
    .product__list--thumb {
      > img {
        max-width: 480px !important;
        max-height: 480px !important;
        width: 100% !important;
      }
    }
  }
}

@media (min-width: $screenMd) {
  .product-list-right-slot {
    margin-left: -20px;
  }

  .product-list-right-component {
    margin-left: 0;
    padding-top: 20px;

    .pagination-bar {
      margin-top: 0;
    }
  }
}
