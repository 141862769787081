/* Here are all the CSS elements related to the Carousel component. */

/* Promotion Carousel Component Container */
.marchiol-promotion-carousel-cont {
  background-color: #f3f3f3;
  margin-top: -80px;
  padding: 80px 0 80px;
}

/* Carousel Component Loader */
.mc-carousel-loader {
  background: url("~images/mc-circle.svg") no-repeat center;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 48%;
  top: 80px;
}

/* Carousel Component */
.carousel-component, .carousel__component {
  @extend .clearfix;
  padding: 0 20px;
  margin: $grid-gutter-width +15 0 ($grid-gutter-width - 5);

  .carousel__component--headline {
    color: $headline-color;
    font-size: 28px;
    margin: 10px 0 20px;
    text-align: center;
    font-weight: bold;
    text-transform: $carousel-font-style;
  }

  &:first-child {
    margin: 50px 0 22px;
  }

  + .carousel-component, .carousel__component {
    margin-top: 0;
    border-top: 4px solid $border-color;
    padding-top: $grid-gutter-width + $grid-gutter-width/2;
  }
}

.carousel__component {
  padding: 0;
  margin: 25px 0 15px;

  .carousel__component--headline, .carousel__component--headline {
    color: #221f20;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 30px;
  }

  .carousel__component--carousel, .carousel {
    .owl-wrapper-outer {
      .owl-wrapper {
        .owl-item {
          height: 520px;

          .carousel__item {
            background-color: #ffffff;
            text-align: left;
            padding: 20px 15px;
            margin: 12px;
            border: 1px solid #e1e1e1;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            transition: all 1s ease-in-out;

            .mc-carousel-info {
              padding-bottom: 10px;
              border-bottom: 1px solid #e1e1e1;

              .mc-product__listing--description {
                font-size: 12px;
                overflow: hidden;
                height: 30px;
              }

              .mc-list-code {
                min-height: 55px;
              }
            }

            .mc-price-detail {
              line-height: 1;
              margin-bottom: 10px;
              float: none;
            }

            .carousel__item--name {
              margin-top: 5px;
              margin-bottom: 5px;
              text-align: left;
              font-size: 12px;
              font-weight: bold;

              &:before {
                display: none;
              }
            }

            .carousel__item--thumb {
              img {
                width: auto;
              }
            }

            .addtocart-component {
              opacity: 0;
              -webkit-transition: visibility 0s, opacity 0.5s linear;
              -moz-transition: visibility 0s, opacity 0.5s linear;
              transition: visibility 0s, opacity 0.5s linear;

              &.mc-visible {
                opacity: 1;
              }

              .mc-addtocart-buttons {
                .addToCartActionCont {
                  width: 100%;
                }
              }
            }

            .mc-product__list--price-panel {
              margin-top: 5px;
            }
          }
        }
      }
    }

    .owl-controls {
      > .owl-buttons {
        .owl-prev, .owl-next {
          color: transparent;

          span {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: auto;
            height: auto;
            font-size: 25px;
            line-height: 125px;
            color: #269b47;
            -webkit-transition: 0.3s ease-in;
            -o-transition: 0.3s ease-in;
            transition: 0.3s ease-in;
          }

          &:hover {
            color: transparent;
          }
        }
      }
    }
  }
}

/* Owl Carousel Component */
.owl-carousel {
  @extend .clearfix;

  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;

  .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;

    &.autoHeight {
      -webkit-transition: height 500ms ease-in-out;
      -moz-transition: height 500ms ease-in-out;
      -ms-transition: height 500ms ease-in-out;
      -o-transition: height 500ms ease-in-out;
      transition: height 500ms ease-in-out;
    }

    .owl-wrapper {
      display: none;
      position: relative;
			//justify-content: center;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);

      &:after {
        @extend .clearfix;
      }

      .owl-item {
        float: left;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);

        &.loading {
          & > * {
            min-height: 1px;
          }
        }
      }
    }
  }

  .owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .owl-buttons {
      .owl-prev, .owl-next {
        @extend .glyphicon;
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: 25px;
        height: 125px;
        text-align: center;
        transform: translate(0, -50%);
        color: rgba(255, 255, 255, 0.7);
        font-size: 120px;
        overflow: hidden;
        transition: 0.3s ease-out;

        span.glyphicon {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: auto;
          height: auto;
          font-size: 25px;
          line-height: 125px;
          color: $link-color;
          transition: 0.3s ease-in;
        }

        &:hover {
          color: rgba(255, 255, 255, 1);

          span.glyphicon {
            color: $link-hover-color;
          }
        }
      }

      .owl-prev {
        @extend .glyphicon-forward;
        left: 3px;
      }

      .owl-next {
        @extend .glyphicon-backward;
        right: 5px;
      }
    }
  }
}

.carousel, .carousel__component--carousel {
  width: auto;
  margin: ($grid-gutter-width / 2) (- $grid-gutter-width / 2);
  text-align: center;

  .item, .carousel__item {
    display: block;
    margin: 0 ($grid-gutter-width / 2);
    padding: ($grid-gutter-width / 2) 0;

    .thumb, .carousel__item--thumb {
      margin-bottom: 20px;
      height: 140px;

      img {
        max-width: 140px;
        max-height: 140px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    a {
      color: $text-color;

      &:hover, &:focus {
        text-decoration: none;
        color: $brand-primary;
      }
    }

    .item__name, .carousel__item--name {
      margin-top: $grid-gutter-width/1.5;
      margin-bottom: 11px;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.2em;
      overflow: hidden;
      position: relative;
      height: 2.4em;
      font-weight: 600;
      font-size: 16px;
      color: $headline-color;

      &:before {
        background: #FFF none repeat scroll 0 0;
        bottom: 0;
        position: absolute;
        right: 0;
        content: "…";
      }

      &:after {
        content: "";
        background: #FFF none repeat scroll 0 0;
        position: absolute;
        height: 50px;
        width: 100%;
        z-index: 1;
      }
    }

    .item__price, .carousel__item--price {
      margin: $grid-gutter-width/2;
      color: $text-color;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      margin: 0;
    }
  }
}

/* Active Item inside Carousel Component */
.mc-carousel-item-active {
  -webkit-box-shadow: rgb(137, 137, 137) 0 0 11px;
  -moz-box-shadow: rgb(137, 137, 137) 0 0 11px;
  box-shadow: rgb(137, 137, 137) 0 0 11px;
}

/* Rotating Image Component for Owl Carousel (not used) */
.rotating-image-componet {
  @extend .clearfix;

  .carousel.rotating-image {
    margin: 0;
  }

  .owl-item {
    .item {
      padding: 0;
      margin: 0;
    }

    img {
      width: 100%;
      height: auto;
      max-width: none;
      max-height: none;
    }
  }

  .owl-controls {
    .owl-pagination {
      display: block;
    }
  }
}

/* Media Queries */

/* ONLY DESKTOP */
@media (min-width: $screenXs) {
  .carousel__component {
    .carousel__component--carousel, .carousel {
      &.owl-center-products-carousel {
        .owl-wrapper {
           width: 100% !important;
           display: flex !important;
           justify-content: center !important;
        }
      }
    }
  }
}
/* ONLY MOBILE */
@media (max-width: $screenXs) {
  .carousel__component {
    .carousel__component--carousel, .carousel {
      .owl-wrapper-outer {
        .owl-wrapper {
          .owl-item {
            padding: 0 10px 0 10px;
            height: 500px;

            .carousel__item {
              .mc-carousel-info {
                padding-left: 15px;

                .mc-product__listing--description {
                  font-size: 12px;
                  overflow: hidden;
                  height: 30px;
                }
              }
            }
          }
        }
      }

      .owl-controls {
        > .owl-buttons {
          .owl-prev, .owl-next {
            margin-left: 15px;
          }

          .owl-next {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
