button {
  font-weight: bold;
  color: $link-color;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

.btn {
  @include button-size($padding-large-vertical, $padding-large-horizontal, $btn-font-size, $btn-line-height, $btn-border-width);
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0;

  &.glyphicon {
    padding: 3px 0 4px 0;

    &:before {
      font-size: $btn-icon-font-size;
    }
  }

  &, &:active, &.active {
    box-shadow: none;

    &:focus {
      outline: 0;
    }
  }
}

.btn-default {
  border: $btn-border-width solid;
  @include button-variant-custom($btn-default-color, $btn-default-bg, $btn-default-border, $btn-default-hover-color, $btn-default-hover-border, $btn-default-hover-background, $btn-default-disabled-bg);
}

.btn-primary {
  border: 3px solid;
  @include button-variant-custom($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-hover-color, $btn-primary-hover-border, $btn-primary-hover-background, $btn-primary-disabled-bg);

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: $btn-primary-hover-bg;
    color: $btn-default-color;
  }
}

.mc-btn-new {
  background-color: $mc-btn-new-bg;
}

/* Link button */
.mc-link-button {
  @extend .btn-primary;
  background-color: transparent;
  border-color: transparent;
  color: $brand-primary;

  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: $brand-primary;
  }
}

.btn-edit-confirm {
  width: 48%;
}

/* Confirm button */
.btn-confirm {
  background-color: $brand-primary;
  border-color: $brand-primary;
}

/* Save Order button */
.btn-save-order {
  background-color: #1c9ed8 !important;
  color: #ffffff !important;

  &:hover {
    background-color: #1C80D8 !important;
    border-color: #1c9ed8 !important;
    color: #ffffff !important;
  }
}

/* Circular button */
.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  padding: 1px;
}

/* Quote button
.btn--mc-quote {
  background-color: $mc-orange;
  border-color: $mc-orange;

  &:hover {
    background-color: #9e4a00;
    border-color: $mc-orange;
    color: #ffffff;
  }
}*/

.mc-close-button {
  margin-right: 15px;
  margin-left: auto;
  margin-top: 10px;

  .btn-circle {
    width: 25px;
    height: 25px;
    background-color: transparent;
    border-color: #6b6d6b;
    color: #6b6d6b;
    border: 1px solid;

    span {
      color: #6b6d6b;
      font-size: 10px;
    }
  }
}

/* Cart button */
.mc-cart-btn {
  border-radius: $mc-border-radius;
  text-transform: unset;
  min-height: 38px;
  align-items: center;
  justify-content: center;
  padding: 3px 16px;
  position: relative;
  overflow: hidden;

  &:before {
    font-size: $font-size-cart-button !important;
    float: right;
  }

  .mc-cart-icon {
    background: url("~images/cart_white.svg") no-repeat center;
    background-size: 15px;
    float: left;
  }

  .mc-cart-button-label {
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 11px;
    display: block;
    font-weight: 600;
    transition: transform .4s;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    [class^="icon-"], [class*=" icon-"] {
      margin-left: 5px;
      font-size: 16px;
      top: 3px;
      position: relative;
    }
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    /* Move the icon on the right - outside the button */
    transform: translateX(50%) translateY(-50%);
    -webkit-transform: translateX(50%) translateY(-50%);
    -moz-transform: translateX(50%) translateY(-50%);
    -ms-transform: translateX(50%) translateY(-50%);
    -o-transform: translateX(50%) translateY(-50%);
    -webkit-transition: -webkit-transform .3s;
    -moz-transition: -moz-transform .3s;
    transition: transform .4s;
  }

  &.is-added {
    .mc-cart-button-label {
      color: transparent;
      transform: translateX(-100%);
    }

    svg {
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      -webkit-transition: -webkit-transform 0s;
      -moz-transition: -moz-transform 0s;
      transition: transform 0s;
    }
  }
}

/* Quick Cart contiguous buttons */
.mcmcCartQuickBtnCont {
  margin-bottom: 15px;

  button.mcCartQuickOrderBtn {
    border-radius: 5px;
    color: #fff;
    background-color: #1c9ed8;
    width: 260px;
    font-size: 14px;
  }

  a {
    > button.mcCartQuickOrderBtn {
      &:before {
        @extend .icon-arrow-down;
        margin-left: 15px;
        font-family: 'icomoon';
        float: right;
      }
    }

    > button.active {
      &:before {
        @extend .icon-arrow-up;
        margin-left: 15px;
        font-family: 'icomoon';
        float: right;
      }
    }
  }
}

/* Add product to wishlist and product info buttons */
.mc-wish-button, .mc-prd-info-button {
  border-radius: $mc-border-radius;
  text-transform: unset;
  height: 38px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    font-size: $font-size-cart-button;
    float: right;
  }

  .mc-cart-icon {
    background: url("~images/cart_white.svg") no-repeat center;
    background-size: 15px;
    float: left;
  }

  .mc-cart-button-label {
    margin-right: 8px;
    font-size: 11px;
    display: block;
    margin-top: 1px;
    font-family: arial;
    line-height: 1.8;
    float: left;
    font-weight: 600;
  }
}

.mc-prd-info-button {
  background-color: #059ae3;
  padding: 5px 10px;
  margin-right: 5px;
}

.mc-wish-button {
  background-color: #dadada;
  padding: 5px 10px;
}

.mc-wish-button.mc-wish-button-tablet {
  display: none;
}

/* Block button */
.btn-block + .btn-block {
  margin-top: $grid-gutter-width / 2;
}

/* Button Sizes */
.btn-small {
  @include button-size($padding-base-vertical, $padding-large-horizontal, $btn-font-size, $btn-line-height, $btn-border-width);
}

.mc-btn-small {
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}

.mc-btn-x-small {
  padding: 5px 5px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}

.in-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #808080;
  -webkit-transition: .4s;
  transition: .4s;
  max-width: 60px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

input.mc-check {
  margin-left: 10px;

  &:checked {
    + .in-slider {
      background-color: $mc-orange;
    }
  }

  &:disabled {
    + .in-slider {
      background-color: #ccc;
    }
  }
}

input:focus + .in-slider {
  box-shadow: 0 0 1px #f2f2f2;
}

input:checked + .in-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.in-slider.round {
  border-radius: 34px;
  margin-left: 5px;
  max-height: 32px;

  &:before {
    border-radius: 50%;
  }
}

.mc-slider {
  margin-top: 21px;
  position: relative;

  .control-label {
    width: 60px;
    margin-right: 10px;
  }

  .slider-label {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    color: #fff;
  }
}

/* Media Queries */

@media (max-width: $screenXs) {
  .stick_menu {
    .icon-hamburger-menu {
      font-size: 24px;
    }
  }

  .mcmcCartQuickBtnCont {
    margin-bottom: 5px;
    margin-top: 15px;

    button.mcCartQuickOrderBtn {
      width: 100%;
      font-size: 9px;
      padding: 0 6px;
      height: 32px;
      line-height: 1;

      .icon-barcode {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  #mcbutton {
    margin-top: 5px;

    .stick_menu {
      .icon-hamburger-menu {
        font-size: 28px;
      }
    }
  }

  .mc-cart-btn {
    .mc-cart-button-label {
      font-size: 8px;
      line-height: 3;
    }
  }

  .mc-tablet-info-btn {
    margin-bottom: 15px;

    span {
      margin-right: 5px;
    }
  }
}

@media (min-width: 1025px) and (max-width: $screenMdBigMax) {
  .mc-cart-btn {
    &, .mc-cart-button-label {
      font-size: 7.5px;
      line-height: 2.4;
      padding-left: 0;
    }
  }
}

@media (min-width: $screenXXl) {
  .mc-prd-info-button {
    margin-right: 10px !important;
  }
}
