/* Here are all the CSS variables related to colors. */

/* Brand */
$brand-primary:   #269b47;
$brand-secondary: #929292;

$brand-info:    #000000;
$brand-success: #5cb85c;
$brand-warning: #f0ad4e;
$brand-danger:  #d9534f;

/* General */
$gray-base:   #000;
$gray-dark:   lighten($gray-base, 20%);   // #333
$gray-light:  lighten($gray-base, 46.7%); // #777

$mc-gray:     #bebebe;
$mc-orange:   #fe7600;

$grayblue:    #f2f4f7;

/* Typography */
$text-color:            #303030;
$text-color-secondary:  $brand-secondary;
$link-color:            $brand-primary;
$headline-color:        $text-color;
$font-mc-tables-color:  #2f353b;

/* Borders */
$border-color:    #d9d9d9;
$border-color-2:  #e5e5e5;
$border-color-3:  #cccccc;
$mc-border-soft:  #eef0f0;

/* Forms */
$label-color:         $brand-secondary;
$input-background:    #ffffff;
$input-color:         $text-color;
$input-border-color:  $border-color-3;
$placeholder-color:   $brand-secondary;
$input-border-focus:  $brand-primary;

$input-error:         #fd7b7b;
$input-error-bg:      #fec3c3;
$input-error-text:    #ed1c24;

$input-success:       $border-color-3;
$input-success-bg:    transparent;
$input-success-text:  #00A651;

/* Header */
$header-background-color:   #ffffff;
$mc-background-top-header:  $mc-orange;

/* Header Secondary Navigation (My Account / My Company Dropdowns) */
$meta-nav-color:            $brand-primary;
$meta-nav-color-open:       #ffffff;
$meta-nav-hover-color:      $brand-secondary;
$meta-nav-border-color:     #265272;
$meta-nav-background:       #003459;
$meta-nav-hover-background: #004678;

/* Header Categories Navigation */
$nav-cat-background:          #ffffff;
$nav-cat-border:              $nav-cat-background;
$nav-cat-hover-background:    #1e3d55;
$nav-cat-link-color:          #2f353b;
$nav-subcat-color:            $text-color;
$nav-subcat-title-color:      rgba(0, 0, 0, 0.7);
$nav-subcat-background:       $header-background-color;
$nav-subcat-border-color:     $border-color-2;
$nav-subcat-hover-background: $border-color-2;

/* Offcanvas Navigation */
$offcanvas-nav-header-color:          $brand-primary;
$offcanvas-nav-header-background:     #ffffff;
$offcanvas-nav-disabled:              $brand-secondary;
$offcanvas-meta-nav-color:            #ffffff;
$offcanvas-meta-nav-border-color:     $meta-nav-border-color;
$offcanvas-meta-nav-background:       #03426f;
$offcanvas-meta-nav-background-open:  $meta-nav-background;
$offcanvas-meta-nav-hover-background: $meta-nav-hover-background;
$offcanvas-nav-cat-color:             $brand-primary;
$offcanvas-nav-cat-title-color:       rgba(0, 0, 0, 0.7);
$offcanvas-nav-cat-background:        #ffffff;
$offcanvas-nav-cat-border-color:      $border-color-2;
$offcanvas-nav-cat-hover-background:  $border-color-2;

/* Navigation Icons */
$nav-icon-color:                #2f353b;
$nav-icon-color-secondary:      darken(#428bca, 6.5%); // #337ab7
$nav-icon-hover-background:     $brand-primary;
$nav-icon-dropdown-background:  #ffffff;
$nav-border-color:              $border-color;

/* Menu */
$font-color-menu: #2f353b;

/* Footer colors */
$footer-background:     #f3f3f3;
$footer-text-color:     #9a9a9a;
$footer-border-color:   $nav-cat-hover-background;
$copyright-background:  #ffffff;
$copyright-color:       $text-color;

/* Well */
$well-secondary-bg:   lighten($brand-primary, 60%); //#e6f0fa;
$well-quaternary-bg:  #f2f2f2;

/* Modal */
$modal-close-btn-color: $brand-secondary;

/* Search */
$searchbar-icon-color:    $text-color;
$check-box-bg:            #ffffff;
$autocomplete-background: #ffffff;

/* Item */
$item-promo-color:      #00A651;
$item-sale-color:       #ED1C24;
$item-sale-price-color: $brand-secondary;

/* List */
$list-active-bg:        #e6f0fb;
$font-color-value-list: #808080;

/* Tables */
$table-2n-line-bg:              #f4f4f4;
$table-head-bg:                 $nav-cat-background;
$table-head-color:              #7f919e;
$table-head-border-color:       #334b5c;
$table-border-color:            $border-color-2;
$table-expand-bg:               #e4e4e4;
$cart-product-table-head-color: $table-expand-bg;

/* Primary Button State */
$btn-primary-color:             #ffffff;
$btn-primary-bg:                $brand-primary;
$btn-primary-border:            $brand-primary;

$btn-primary-hover-color:       $brand-primary;
$btn-primary-hover-border:      $brand-primary;
$btn-primary-hover-background:  #ffffff;
$btn-primary-disabled-bg:       #237d44;
$btn-primary-hover-bg:          $btn-primary-disabled-bg;

/* Secondary Button State */
$btn-default-color:             #ffffff;
$btn-default-bg:                $brand-secondary;
$btn-default-border:            $brand-secondary;

$btn-default-hover-color:       $brand-secondary;
$btn-default-hover-border:      $brand-secondary;
$btn-default-hover-background:  #ffffff;
$btn-default-disabled-bg:       $border-color-3;
$mc-product-icons-color:        #e2e2e2;
$mc-btn-new-bg:                 #019d9c;

$btn-success-color:             #fff;
$btn-success-bg:                $brand-success;
$btn-success-border:            transparent;

$btn-info-color:                #fff;
$btn-info-bg:                   $brand-info;
$btn-info-border:               transparent;

$btn-warning-color:             #fff;
$btn-warning-bg:                $brand-warning;
$btn-warning-border:            transparent;

$btn-danger-color:              #fff;
$btn-danger-bg:                 $brand-danger;
$btn-danger-border:             transparent;

$btn-link-disabled-color:       $gray-light;

/* Breadcrumbs */
$breadcrumb-color:          #000000;
$breadcrumb-active-color:   #000000;
$breadcrumb-bg:             $border-color;
$mc-breadcrumb-background:  #2e353d;

/* Captcha Addon */
$captcha-icons: #353c45;

/* Account */
$account-well-primary:    #ffffff;
$account-well-secondary:  #ffffff;
$account-subheadline-bg:  $header-background-color;

/* Cart */
$mc-active-icon-color:  #479fe5;

/* Checkout */
$checkout-icon:             $border-color-3;
$checkout-list-head-bg:     $table-head-bg;
$checkout-list-head-color:  #ffffff;
$checkout-list-head-border: $table-head-bg;

$steps-divider:             #000000;
$steps-color:               $text-color;
$steps-background:          $header-background-color;
$steps-color-active:        $steps-color;
$steps-background-active:   #e4e4e4;
$steps-border-active:       $border-color-3;

/* Datepicker */
$datepicker-highlight-bg:     $brand-secondary;
$datepicker-highlight-color:  #ffffff;
$datepicker-highlight-border: $brand-secondary;

$datepicker-active-bg:        #000000;
$datepicker-active-color:     #ffffff;
$datepicker-active-border:    #000000;

/* Entry Group */
$entry-group-text:    #000000;
$entry-group-header:  #e4e4e4;
$entry-group-item:    #f4f4f4;

/* Pagination */
$pagination-border:                 #000000;
$pagination-hover-bg:               transparent;

$pagination-active-color:           #a6a6a6;

$pagination-disabled-color:         $pagination-active-color;
$pagination-hover-color:            $pagination-active-color;

$pagination-prev-next-hover-bg:     $btn-primary-bg;
$pagination-prev-next-hover-color:  $btn-primary-color;

/* Form States and Alerts */
$state-success-text:    #3e5983;
$state-success-bg:      #dff0d8;
$state-success-border:  darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text:       #31708f;
$state-info-bg:         #d9edf7;
$state-info-border:     darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text:    #8a6d3b;
$state-warning-bg:      #fcf8e3;
$state-warning-border:  darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text:     #000000;
$state-danger-bg:       #f2dede;
$state-danger-border:   darken(adjust-hue($state-danger-bg, -10), 5%);

/* Panels */
$panel-default-text:        $gray-dark;
$panel-default-border:      #ddd;
$panel-default-heading-bg:  #000000;

/* Progress Bar */
$progress-bg:             #f5f5f5;
$progress-bar-color:      #fff;
$progress-bar-bg:         #000000;
$progress-bar-success-bg: $brand-success;
$progress-bar-warning-bg: $brand-warning;
$progress-bar-danger-bg:  $brand-danger;
$progress-bar-info-bg:    $brand-info;

/* Accordion */
$accordion-bg:  #000000;

/* Coupon */
$coupon-list-item-border:       $border-color-3;
$coupon-list-item-bg:           $table-2n-line-bg;
$coupon-list-item-remove-icon:  #cdcdcd;
