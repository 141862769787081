/* Font Family */
$font-family-sans-serif: 'Montserrat', sans-serif;

/* Font Sizes */
$font-size-base:            14px;
$font-size-small:           ceil(($font-size-base * 0.92)); // ~13px
$font-size-large-1:         ceil(($font-size-base * 1.14)); // ~16px
$font-size-large-2:         ceil(($font-size-base * 1.2857)); // ~18px
$font-size-large-3:         ceil(($font-size-base * 1.57)); // ~22px
$font-size-large-4:         ceil(($font-size-base * 1.71)); // ~24px
$btn-font-size:					    16px;
$btn-icon-font-size:		    25px;
$nav-cat-font-size:         12px;
$font-size-menu-link:       11px;
$nav-icon-color-font-size:  18px;
$item-value-font-size:      18px;
$font-size-cart-button:     16px;
$pagination-font-size:		  $font-size-base;

/* Buttons */
$btn-box-shadow:				false;
$btn-border-width:      3px;
$btn-line-height:				1.375;
$btn-font-weight:       normal;
$base-icon-touch-area:	50px;

/* Form states and alerts */
$grid-gutter-width:             20px;
$input-padding-vertical-small:  9px;
$padding-large-vertical:        10px;
$padding-large-horizontal:      15px;
$line-height-base:              1.428571429; // 20/14
$input-height-base:             32px;
$input-height-small:            (ceil($font-size-base * $line-height-base) + ($input-padding-vertical-small * 2)+1);
$input-height-large:            42px;

/* Width */
$full-width: 100%;

/* Border Radius */
$border-radius-base:      0;
$border-radius-large:     0;
$border-radius-small:     0;
$pager-border-radius:     0;
$mc-border-radius:        5px;

/* Navigation */
$navigation--middle-height: 60px;
$mobile-nav-icon-font-size: 30px;

/* Item Image Width */
$max-item-image-width: 96px;

/* Cart Totals Padding */
$cart-totals-padding-right: 55px;

/* Breadcrumbs */
$breadcrumb-padding-vertical:   $grid-gutter-width / 2;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-separator:          "/";

/* Carousel */
$carousel-font-style: uppercase;

/* Coupon List Padding */
$voucherListItemPaddingVertical: $grid-gutter-width / 2;

/* Checkout */
$checkout-list-head-padding:        15px 20px;
$checkout-list-head-padding-first:  15px;
$step-height:                       49px;
$step-height-mobile:                68px;

/* Store Finder */
$store-finder-item-height:      70px;
$store-finder-item-arrow-size:  $store-finder-item-height / 2;

/* ColorBox */
$cboxTitleHeight:       88px;
$cboxTitleLineHeight:   24px;

/* Pagination */
$pagination-bar-framed:				    false;
$pagination-border-width: 		    1px;
$pagination-height-base: 			    $input-height-base - ($pagination-border-width * 2);
$pagination-height-large:         $input-height-large - ($pagination-border-width * 2);
$pagination-width-base: 			    $pagination-height-base;
$pagination-width-large:			    40px;
$pagination-font-weight:			    600;
$pagination-active-font-weight:		400;
