/** ==================== ICOMOON ==================== **/

@font-face {
  font-family: 'icomoon';
  src: url('~fonts/icomoon.eot?l9jivh');
  src: url('~fonts/icomoon.eot?l9jivh#iefix') format('embedded-opentype'),
  url('~fonts/icomoon.ttf?l9jivh') format('truetype'),
  url('~fonts/icomoon.woff?l9jivh') format('woff'),
  url('~fonts/icomoon.svg?l9jivh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url("~fonts/Montserrat-Thin.eot");
  src: url("~fonts/Montserrat-Thin.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-Thin.woff2") format("woff2"),
  url("~fonts/Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: italic;
  src: url("~fonts/Montserrat-ThinItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("~fonts/Montserrat-ExtraLight.eot");
  src: url("~fonts/Montserrat-ExtraLight.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-ExtraLight.woff2") format("woff2"),
  url("~fonts/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: italic;
  src: url("~fonts/Montserrat-ExtraLightItalic.eot");
  src: url("~fonts/Montserrat-ExtraLightItalic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("~fonts/Montserrat-Light.eot");
  src: url("~fonts/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-Light.woff2") format("woff2"),
  url("~fonts/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  src: url("~fonts/Montserrat-LightItalic.eot");
  src: url("~fonts/Montserrat-LightItalic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-LightItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("~fonts/Montserrat-Regular.eot");
  src: url("~fonts/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-Regular.woff2") format("woff2"),
  url("~fonts/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("~fonts/Montserrat-Italic.eot");
  src: url("~fonts/Montserrat-Italic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-Italic.woff2") format("woff2"),
  url("~fonts/Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("~fonts/Montserrat-Medium.eot");
  src: url("~fonts/Montserrat-Medium.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-Medium.woff2") format("woff2"),
  url("~fonts/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("~fonts/Montserrat-MediumItalic.eot");
  src: url("~fonts/Montserrat-MediumItalic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-MediumItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("~fonts/Montserrat-SemiBold.eot");
  src: url("~fonts/Montserrat-SemiBold.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-SemiBold.woff2") format("woff2"),
  url("~fonts/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  src: url("~fonts/Montserrat-SemiBoldItalic.eot");
  src: url("~fonts/Montserrat-SemiBoldItalic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("~fonts/Montserrat-Bold.eot");
  src: url("~fonts/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-Bold.woff2") format("woff2"),
  url("~fonts/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("~fonts/Montserrat-BoldItalic.eot");
  src: url("~fonts/Montserrat-BoldItalic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-BoldItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: url("~fonts/Montserrat-ExtraBold.eot");
  src: url("~fonts/Montserrat-ExtraBold.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-ExtraBold.woff2") format("woff2"),
  url("~fonts/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  src: url("~fonts/Montserrat-ExtraBoldItalic.eot");
  src: url("~fonts/Montserrat-ExtraBoldItalic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("~fonts/Montserrat-Black.eot");
  src: url("~fonts/Montserrat-Black.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-Black.woff2") format("woff2"),
  url("~fonts/Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("~fonts/Montserrat-BlackItalic.eot");
  src: url("~fonts/Montserrat-BlackItalic.eot?#iefix") format('embedded-opentype'),
  url("~fonts/Montserrat-BlackItalic.woff2") format("woff2"),
  url("~fonts/Montserrat-BlackItalic.woff") format("woff");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Black **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 900;
  font-style: normal;
  src: url("~fonts/MontserratAlternates-Black.eot");
  src: url("~fonts/MontserratAlternates-Black.eot?#iefix") format('embedded-opentype'),
  url("~fonts/MontserratAlternates-Black.woff2") format("woff2"),
  url("~fonts/MontserratAlternates-Black.woff") format("woff");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("~fonts/MontserratAlternates-BlackItalic.eot");
  src: url("~fonts/MontserratAlternates-BlackItalic.eot?#iefix") format('embedded-opentype');
}
