/* Button Variant Function */
@mixin button-variant-custom($color, $background, $border, $hoverColor, $hoverBorder, $hoverBackground, $disabledBackground) {
	color: $color;
	background-color: $background;
	border-color: $border;
	@if $btn-box-shadow {
	  @include box-shadow(0 2px 2px 0 darken($background, 25%));
  }

	&:hover,
	&:focus,
	&:active,
	&.active,
	.open .dropdown-toggle {
		color: $hoverColor;
		background-color: $hoverBackground;
		border-color: $hoverBorder;
		@include box-shadow(none);
	}

	&:active,
	&.active,
	.open .dropdown-toggle {
		background-image: none;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			color: $color;
			background-color: $disabledBackground;
			border-color: $disabledBackground;
			@if $btn-box-shadow {
      	  @include box-shadow(0 2px 2px 0 darken($background, 25%));
        }
		}
	}

	.badge {
		color: $background;
		background-color: $color;
	}
}

/* Pagination Size Function */
@mixin pagination-size-custom($pagination-height, $pagination-width, $font-size, $next-prev-offset) {
	> li {
		> a,
		> span {
			line-height: $pagination-height;
			width: $pagination-width;
			padding: 0;
			font-size: $font-size;
			text-align: center;
		}
		&.pagination-prev {
			> a,
			> span {
				margin-right: $pagination-width / 3;
				width: $pagination-width + $next-prev-offset;
				@include border-left-radius(0);
			}
		}

		&.pagination-next {
			> a,
			> span {
				margin-left: $pagination-width / 3;
				width: $pagination-width + $next-prev-offset;
				@include border-right-radius(0);
			}
    }
	}
}

/* Pagination Bar Borders Function */
@mixin pagination-bar-borders() {
  @if $pagination-bar-framed {
    .pagination-bar {
      .pagination {
        padding-top: $grid-gutter-width - 5;
        padding-bottom: $grid-gutter-width - 5;
        border-top: 1px solid $border-color-3;
        border-bottom: 1px solid $border-color-3;
      }
    }
  }
}

/* Form Control Validation Function */
@mixin form-control-validation-custom($label-color, $border-color, $background-color, $hint-color) {
  .control-label {
    color: $label-color;
  }

  input.form-control {
    background: $background-color;
    border-color: $border-color;
  }

  .help-block {
    font-weight: 600;
    color: $hint-color;
  }
}

/* Flex Display Function */
@mixin flex-display($value) {
  display: "-webkit-@{value}";
  display: "-moz-@{value}";
  display: "-ms-@{value}box";
  display: "-ms-@{value}";
  display: $value;
}

/* Flex Direction Function */
@mixin flex-direction($value) {
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

/* Flex Wrap Function */
@mixin flex-wrap($value) {
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

/* Accordion Background Function */
@mixin accordion-bg($n, $i: 1) {
  @if $i <= $n {
    $left-spacing: 15 + $grid-gutter-width * ($i + 2);

    .panel-heading {
      background-color: lighten($accordion-bg, (95% -($i *5)));

      .accordion-lnk {
        left: $left-spacing;

        &.no-subs {
          padding-left: $left-spacing;
        }
      }
    }

    .panel-collapse {
      @include accordion-bg($n, ($i + 1));
    }
  }
}

/* Cart Voucher Placeholder Function */
@mixin cart-voucher-placeholder() {
  font-size: $font-size-small;
  font-weight: 600;
  color: $text-color;
  text-transform: uppercase;
}
